import React, { useEffect } from 'react';
import { Footer, Navbar } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { delCart } from '../redux/action'; // Import the delCart action
import userpool from '../pages/userpool';

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.handleCart);
  const { register, handleSubmit, setValue, formState: { errors } } = useForm();

  useEffect(() => {
    const checkUserSession = () => {
      const currentUser = userpool.getCurrentUser();

      if (currentUser) {
        currentUser.getSession((err, session) => {
          if (err || !session.isValid()) {
            console.error('Error fetching user session:', err);
            alert('Login is required to access the checkout page.');
            navigate('/login');
            return;
          }

          const userEmail = session.getIdToken().payload.email;
          setValue('email', userEmail);
          console.log("Logged-in user's email:", userEmail); // Log the email of the logged-in user
        });
      } else {
        console.error('No current user');
        alert('Login is required to access the checkout page.');
        navigate('/login');
      }
    };

    checkUserSession();
  }, [navigate, setValue]);

  const onSubmit = async (formData) => {
    const orderDetails = {
      email: formData.email,
      items: state.map(item => ({
        productId: String(item.id),
        quantity: item.qty,
        price: item.price
      })),
      totalAmount: state.reduce((acc, item) => acc + item.price * item.qty, 0)
    };

    console.log(JSON.stringify(orderDetails));

    try {
      const response = await fetch("https://8h5honhhbj.execute-api.us-east-1.amazonaws.com/prod/pushOrder", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderDetails),
      });
      if (!response.ok) {
        throw new Error('Failed to submit order');
      }
      console.log('Order submitted successfully:', response);
      alert("Thank you for your order!");
      
      // Clear the cart after successful order submission
      state.forEach(item => dispatch(delCart(item)));

      navigate('/');
    } catch (error) {
      console.error('Error submitting order:', error);
      // Handle error (e.g., show an error message)
    }
  };

  const EmptyCart = () => (
    <div className="container">
      <div className="row">
        <div className="col-md-12 py-5 bg-light text-center">
          <h4 className="p-3 display-5">No item in Cart</h4>
          <Link to="/" className="btn btn-outline-dark mx-4">
            <i className="fa fa-arrow-left"></i> Continue Shopping
          </Link>
        </div>
      </div>
    </div>
  );

  const ShowCheckout = () => {
    let subtotal = 0;
    let shipping = 30.0;
    let totalItems = 0;
    state.forEach((item) => {
      subtotal += item.price * item.qty;
      totalItems += item.qty;
    });

    return (
      <>
        <div className="container py-5">
          <div className="row my-4">
            <div className="col-md-5 col-lg-4 order-md-last">
              <div className="card mb-4">
                <div className="card-header py-3 bg-light">
                  <h5 className="mb-0">Order Summary</h5>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0">
                      Products ({totalItems})<span>${Math.round(subtotal)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                      Shipping
                      <span>${shipping}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3">
                      <div>
                        <strong>Total amount</strong>
                      </div>
                      <span>
                        <strong>${Math.round(subtotal + shipping)}</strong>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-8">
              <div className="card mb-4">
                <div className="card-header py-3">
                  <h4 className="mb-0">Billing address</h4>
                </div>
                <div className="card-body">
                  <form className="needs-validation" onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className="row g-3">
                      <div className="col-sm-6 my-1">
                        <label htmlFor="firstName" className="form-label">First name</label>
                        <input
                          type="text"
                          className={`form-control ${errors.firstName ? 'is-invalid' : ''}`}
                          id="firstName"
                          {...register('firstName', { required: true })}
                        />
                        {errors.firstName && <div className="invalid-feedback">First name is required.</div>}
                      </div>

                      <div className="col-sm-6 my-1">
                        <label htmlFor="lastName" className="form-label">Last name</label>
                        <input
                          type="text"
                          className={`form-control ${errors.lastName ? 'is-invalid' : ''}`}
                          id="lastName"
                          {...register('lastName', { required: true })}
                        />
                        {errors.lastName && <div className="invalid-feedback">Last name is required.</div>}
                      </div>

                      <div className="col-12 my-1">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                          type="email"
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          id="email"
                          {...register('email', { required: true })}
                        />
                        {errors.email && <div className="invalid-feedback">Please enter a valid email address.</div>}
                      </div>

                      <div className="col-12 my-1">
                        <label htmlFor="address" className="form-label">Address</label>
                        <input
                          type="text"
                          className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                          id="address"
                          {...register('address', { required: true })}
                        />
                        {errors.address && <div className="invalid-feedback">Address is required.</div>}
                      </div>

                      <div className="col-12 my-1">
                        <label htmlFor="address2" className="form-label">Address 2 <span className="text-muted">(Optional)</span></label>
                        <input
                          type="text"
                          className="form-control"
                          id="address2"
                          {...register('address2')}
                        />
                      </div>

                      <div className="col-md-5 my-1">
                        <label htmlFor="country" className="form-label">Country</label>
                        <br />
                        <select className={`form-select ${errors.country ? 'is-invalid' : ''}`} id="country" {...register('country', { required: true })}>
                          <option value="">Choose...</option>
                          <option value="India">India</option>
                        </select>
                        {errors.country && <div className="invalid-feedback">Please select a country.</div>}
                      </div>

                      <div className="col-md-4 my-1">
                        <label htmlFor="state" className="form-label">State</label>
                        <br />
                        <select className={`form-select ${errors.state ? 'is-invalid' : ''}`} id="state" {...register('state', { required: true })}>
                          <option value="">Choose...</option>
                          <option value="Punjab">Punjab</option>
                        </select>
                        {errors.state && <div className="invalid-feedback">Please select a state.</div>}
                      </div>

                      <div className="col-md-3 my-1">
                        <label htmlFor="zip" className="form-label">Zip</label>
                        <input
                          type="text"
                          className={`form-control ${errors.zip ? 'is-invalid' : ''}`}
                          id="zip"
                          {...register('zip', { required: true })}
                        />
                        {errors.zip && <div className="invalid-feedback">Zip code is required.</div>}
                      </div>
                    </div>

                    <hr className="my-4" />

                    <h4 className="mb-3">Payment</h4>

                    <div className="row gy-3">
                                           <div className="col-md-6">
                        <label htmlFor="cc-name" className="form-label">Name on card</label>
                        <input
                          type="text"
                          className={`form-control ${errors.paymentDetails?.nameOnCard ? 'is-invalid' : ''}`}
                          id="cc-name"
                          {...register('paymentDetails.nameOnCard', { required: true })}
                        />
                        {errors.paymentDetails?.nameOnCard && <div className="invalid-feedback">Name on card is required</div>}
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="cc-number" className="form-label">Credit card number</label>
                        <input
                          type="text"
                          className={`form-control ${errors.paymentDetails?.cardNumber ? 'is-invalid' : ''}`}
                          id="cc-number"
                          {...register('paymentDetails.cardNumber', { required: true })}
                        />
                        {errors.paymentDetails?.cardNumber && <div className="invalid-feedback">Credit card number is required</div>}
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="cc-expiration" className="form-label">Expiration</label>
                        <input
                          type="text"
                          className={`form-control ${errors.paymentDetails?.expiration ? 'is-invalid' : ''}`}
                          id="cc-expiration"
                          {...register('paymentDetails.expiration', { required: true })}
                        />
                        {errors.paymentDetails?.expiration && <div className="invalid-feedback">Expiration date is required</div>}
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="cc-cvv" className="form-label">CVV</label>
                        <input
                          type="text"
                          className={`form-control ${errors.paymentDetails?.cvv ? 'is-invalid' : ''}`}
                          id="cc-cvv"
                          {...register('paymentDetails.cvv', { required: true })}
                        />
                        {errors.paymentDetails?.cvv && <div className="invalid-feedback">CVV is required</div>}
                      </div>
                    </div>

                    <hr className="my-4" />

                    <button
                      className="w-100 btn btn-primary"
                      type="submit"
                    >
                      Continue to checkout
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Checkout</h1>
        <hr />
        {state.length ? <ShowCheckout /> : <EmptyCart />}
      </div>
      <Footer />
    </>
  );
};

export default Checkout;
