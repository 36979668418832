// import React, { useEffect, useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import { CognitoUserPool } from 'amazon-cognito-identity-js';
// import { useSelector } from 'react-redux';
// import useAuth from '../pages/useAuth'; // Custom hook to check authentication
// import { logout } from '../pages/AuthService'; // Function to handle logout
// import { useNavigate } from 'react-router-dom'; // For navigation after logout

// const Navbar = () => {
//   const state = useSelector(state => state.handleCart);
//   const isAuthenticated = useAuth(); // Check if user is authenticated
//   const navigate = useNavigate();
//   const [username, setUsername] = useState(null);

//   useEffect(() => {
//     const getUserData = async () => {
//       const userPool = new CognitoUserPool({
//         UserPoolId: 'us-east-1_tAsvjzrLg',
//         ClientId: '6s9871odv132n3eui3ust8mbj5'
//       });

//       const cognitoUser = userPool.getCurrentUser();

//       if (cognitoUser !== null) {
//         cognitoUser.getSession((err, session) => {
//           if (err) {
//             console.error('Error fetching session:', err);
//             return;
//           }

//           const username = cognitoUser.getUsername();
//           setUsername(username);
//         });
//       }
//     };

//     getUserData();
//   }, []);

//   const handleLogout = () => {
//     logout();
//     navigate('/login'); // Redirect to login after logout
//   };

//   return (
//     <nav className="navbar navbar-expand-lg navbar-light bg-light py-3 sticky-top">
//       <div className="container">
//         <NavLink className="navbar-brand fw-bold fs-4 px-2" to="/">React Ecommerce</NavLink>
//         <button className="navbar-toggler mx-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//           <span className="navbar-toggler-icon"></span>
//         </button>

//         <div className="collapse navbar-collapse" id="navbarSupportedContent">
//           <ul className="navbar-nav m-auto my-2 text-center">
//             <li className="nav-item">
//               <NavLink className="nav-link" to="/">Home</NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink className="nav-link" to="/product">Products</NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink className="nav-link" to="/about">About</NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink className="nav-link" to="/contact">Contact</NavLink>
//             </li>
//             <li className="nav-item">
//               <NavLink className="nav-link" to="/pastorders">Orders</NavLink>
//             </li>
//           </ul>
//           <div className="buttons text-center">
//             {isAuthenticated ? (
//               <>
//                 <div className="mr-3">Welcome {username}</div>
//                 <button onClick={handleLogout} className="btn btn-outline-dark m-2">
//                   <i className="fa fa-sign-out-alt mr-1"></i> Logout
//                 </button>
//               </>
//             ) : (
//               <>
//                 <NavLink to="/login" className="btn btn-outline-dark m-2">
//                   <i className="fa fa-sign-in-alt mr-1"></i> Login
//                 </NavLink>
//                 <NavLink to="/register" className="btn btn-outline-dark m-2">
//                   <i className="fa fa-user-plus mr-1"></i> Register
//                 </NavLink>
//               </>
//             )}
//             <NavLink to="/cart" className="btn btn-outline-dark m-2">
//               <i className="fa fa-cart-shopping mr-1"></i> Cart ({state.length})
//             </NavLink>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CognitoUser } from 'amazon-cognito-identity-js';
import useAuth from '../pages/useAuth'; // Custom hook to check authentication
import { logout } from '../pages/AuthService'; // Function to handle logout
import userpool from '../pages/userpool'; // Import userpool configuration

const Navbar = () => {
  const state = useSelector(state => state.handleCart);
  const isAuthenticated = useAuth(); // Check if user is authenticated
  const navigate = useNavigate();
  const [username, setUsername] = useState(null);

  useEffect(() => {
    const getUserData = async () => {
      const cognitoUser = userpool.getCurrentUser();

      if (cognitoUser !== null) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error('Error fetching session:', err);
            return;
          }

          const username = cognitoUser.getUsername();
          setUsername(username);
        });
      }
    };

    getUserData();
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirect to login after logout
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light py-3 sticky-top">
      <div className="container">
        <NavLink className="navbar-brand fw-bold fs-4 px-2" to="/">React Ecommerce</NavLink>
        <button className="navbar-toggler mx-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto my-2 text-center">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/product">Products</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about">About</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">Contact</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/pastorders">Orders</NavLink>
            </li>
          </ul>
          <div className="buttons text-center">
            {isAuthenticated ? (
              <>
                <div className="mr-3">Welcome {username}</div>
                <button onClick={handleLogout} className="btn btn-outline-dark m-2">
                  <i className="fa fa-sign-out-alt mr-1"></i> Logout
                </button>
              </>
            ) : (
              <>
                <NavLink to="/login" className="btn btn-outline-dark m-2">
                  <i className="fa fa-sign-in-alt mr-1"></i> Login
                </NavLink>
                <NavLink to="/register" className="btn btn-outline-dark m-2">
                  <i className="fa fa-user-plus mr-1"></i> Register
                </NavLink>
              </>
            )}
            <NavLink to="/cart" className="btn btn-outline-dark m-2">
              <i className="fa fa-cart-shopping mr-1"></i> Cart ({state.length})
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
