import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import userpool from '../pages/userpool';

export const authenticate = (Email, Password) => {
  return new Promise((resolve, reject) => {
    console.log("Authenticating user with email:", Email);

    const user = new CognitoUser({
      Username: Email,
      Pool: userpool
    });

    const authDetails = new AuthenticationDetails({
      Username: Email,
      Password
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        console.log("Login successful");
        resolve(result);
      },
      onFailure: (err) => {
        console.log("Login failed", err);
        reject(err);
      }
    });
  });
};

export const logout = () => {
  const user = userpool.getCurrentUser();
  if (user) {
    user.signOut();
  }
};
