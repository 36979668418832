// import { useEffect, useState } from 'react';
// import { useNavigate } from "react-router-dom";
// import { logout } from '../pages/AuthService';
// import userpool from '../pages/userpool';

// const useAuth = () => {
//   const [authenticated, setAuthenticated] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const checkAuth = async () => {
//       const user = userpool.getCurrentUser();
//       if (user) {
//         try {
//           const session = await new Promise((resolve, reject) => {
//             user.getSession((err, session) => {
//               if (err) {
//                 reject(err);
//               } else {
//                 resolve(session);
//               }
//             });
//           });

//           if (session.isValid()) {
//             setAuthenticated(true);
//           } else {
//             // Refresh tokens if session is not valid
//             const refreshSession = new Promise((resolve, reject) => {
//               user.refreshSession(session.getRefreshToken(), (err, newSession) => {
//                 if (err) {
//                   reject(err);
//                 } else {
//                   resolve(newSession);
//                 }
//               });
//             });

//             await refreshSession;
//             setAuthenticated(true);
//           }
//         } catch (error) {
//           console.error('Session expired or not authenticated:', error);
//           logout();
//         }
//       } else {
//         console.log('No user found, not authenticated.');
//         setAuthenticated(false);
//       }
//     };

//     checkAuth();
//   }, [navigate]);

//   return authenticated;
// };

// export default useAuth;

import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { logout } from '../pages/AuthService';
import userpool from '../pages/userpool';

const useAuth = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const user = userpool.getCurrentUser();
      if (user) {
        try {
          const session = await new Promise((resolve, reject) => {
            user.getSession((err, session) => {
              if (err) {
                reject(err);
              } else {
                resolve(session);
              }
            });
          });

          if (session.isValid()) {
            setAuthenticated(true);
          } else {
            // Refresh tokens if session is not valid
            const newSession = await new Promise((resolve, reject) => {
              user.refreshSession(session.getRefreshToken(), (err, newSession) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(newSession);
                }
              });
            });

            if (newSession.isValid()) {
              setAuthenticated(true);
            } else {
              throw new Error('Session is not valid after refresh');
            }
          }
        } catch (error) {
          console.error('Session expired or not authenticated:', error);
          logout();
          navigate('/login');
        }
      } else {
        console.log('No user found, not authenticated.');
        setAuthenticated(false);
      }
    };

    checkAuth();
  }, [navigate]);

  return authenticated;
};

export default useAuth;
