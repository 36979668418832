import { CognitoUserPool } from 'amazon-cognito-identity-js';

const poolData = {
 UserPoolId : 'us-east-1_tAsvjzrLg',
    ClientId : '6s9871odv132n3eui3ust8mbj5',
};

const userPool = new CognitoUserPool(poolData);

export default userPool;
