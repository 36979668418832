import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams, Link } from 'react-router-dom';
import { Footer, Navbar } from '../components';

const ReviewPage = () => {
  const { id } = useParams();
  const [reviews, setReviews] = useState([]);
  const [loadingReviews, setLoadingReviews] = useState(false);
  const [username, setUsername] = useState('');
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState('1');
  const [submittingReview, setSubmittingReview] = useState(false);

  useEffect(() => {
    const fetchReviews = async () => {
      if (!id) return;
      setLoadingReviews(true);
      try {
        const response = await fetch(`https://8h5honhhbj.execute-api.us-east-1.amazonaws.com/prod/reviews?id=${id}`);
        const data = await response.json();
        if (data.items) {
          setReviews(data.items);
        }
      } catch (error) {
        console.error('Error fetching reviews:', error);
      } finally {
        setLoadingReviews(false);
      }
    };

    fetchReviews();
  }, [id]);

  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    setSubmittingReview(true);

    const newReview = {
      UserName: username,
      review: comment,
      rating: rating,
      id: id
    };

    try {
      const response = await fetch(`https://8h5honhhbj.execute-api.us-east-1.amazonaws.com/prod/postReview`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(newReview)
      });

      if (response.ok) {
        setReviews([...reviews, newReview]);
        setUsername('');
        setComment('');
        setRating('1');
      } else {
        console.error('Failed to submit review:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting review:', error);
    }

    setSubmittingReview(false);
  };

  const LoadingReviews = () => (
    <div className="my-5 py-2">
      <Skeleton count={5} height={100} />
    </div>
  );

  return (
    <>
      <Navbar />
      <div className="container my-5 py-2">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <Link to={`/product/${id}`} className="btn btn-outline-dark mb-3">
              &lt; Back to Product
            </Link>
            <h2 className="mb-4">Customer Reviews</h2>
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
              {loadingReviews ? (
                <LoadingReviews />
              ) : (
                reviews.map((review, index) => (
                  <div key={index} className="card mb-3">
                    <div className="card-body">
                      <h5 className="card-title">{review.UserName}</h5>
                      <p className="card-text">{review.review}</p>
                      <p className="card-text">Rating: {review.rating} <i className="fa fa-star"></i></p>
                    </div>
                  </div>
                ))
              )}
            </div>
            <hr />
            <h3 className="mb-4">Write a Review</h3>
            <form onSubmit={handleReviewSubmit}>
              <div className="form-group">
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="comment">Comment:</label>
                <textarea
                  className="form-control"
                  id="comment"
                  rows="3"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="rating">Rating:</label>
                <select
                  className="form-control"
                  id="rating"
                  value={rating}
                  onChange={(e) => setRating(e.target.value)}
                  required
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary" disabled={submittingReview}>
                {submittingReview ? 'Submitting...' : 'Submit Review'}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReviewPage;
