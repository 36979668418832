import React, { useEffect, useState, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link, useParams } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import { useDispatch } from 'react-redux';
import { addCart } from '../redux/action';
import { Footer, Navbar } from '../components';
import LazyLoad from 'react-lazyload';

const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);

  const dispatch = useDispatch();

  const addProduct = useCallback((product) => {
    dispatch(addCart(product));
  }, [dispatch]);

  useEffect(() => {
    const fetchProductData = async () => {
      setLoading(true);
      setLoading2(true);
      try {
        const productResponse = await fetch(`https://8h5honhhbj.execute-api.us-east-1.amazonaws.com/prod/id?id=${id}`);
        const productData = await productResponse.json();

        const similarProductsResponse = fetch(`https://8h5honhhbj.execute-api.us-east-1.amazonaws.com/prod/category?category=${productData.category}`);
        const similarProductsData = await (await similarProductsResponse).json();

        setProduct(productData);
        setSimilarProducts(similarProductsData);
      } catch (error) {
        console.error('Error fetching product data:', error);
      } finally {
        setLoading(false);
        setLoading2(false);
      }
    };

    fetchProductData();
    window.scrollTo(0, 0);
  }, [id]);

  const ShowProduct = () => (
    <div className="container my-5 py-2">
      <div className="row">
        <div className="col-md-6 col-sm-12 py-3">
          <LazyLoad height={400}>
            <img
              className="img-fluid"
              src={product.image}
              alt={product.title}
              width="400px"
              height="400px"
            />
          </LazyLoad>
        </div>
        <div className="col-md-6 col-md-6 py-5">
          <h4 className="text-uppercase text-muted">{product.category}</h4>
          <h1 className="display-5">{product.title}</h1>
          <p className="lead">
            {product.rating && product.rating.rate}{" "}
            <i className="fa fa-star"></i>
          </p>
          <h3 className="display-6 my-4">${product.price}</h3>
          <p className="lead">{product.description}</p>
          <button
            className="btn btn-outline-dark"
            onClick={() => addProduct(product)}
          >
            Add to Cart
          </button>
          <Link to="/cart" className="btn btn-dark mx-3">
            Go to Cart
          </Link>
          <Link to={`/product/${id}/reviews`} className="btn btn-primary mx-3">
            Read Reviews
          </Link>
        </div>
      </div>
    </div>
  );

  const Loading2 = () => (
    <div className="my-4 py-4">
      <div className="d-flex">
        {[1, 2, 3, 4].map((index) => (
          <div key={index} className="mx-4">
            <Skeleton height={400} width={250} />
          </div>
        ))}
      </div>
    </div>
  );

  const ShowSimilarProduct = () => (
    <div className="py-4 my-4">
      <div className="d-flex">
        {similarProducts.map((item) => (
          item.id !== product.id && (
            <div key={item.id} className="card mx-4 text-center">
              <LazyLoad height={300}>
                <img
                  className="card-img-top p-3"
                  src={item.image}
                  alt={item.title}
                  height={300}
                  width={300}
                />
              </LazyLoad>
              <div className="card-body">
                <h5 className="card-title">
                  {item.title.substring(0, 15)}...
                </h5>
              </div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item lead">${item.price}</li>
              </ul>
              <div className="card-body">
                <Link to={`/product/${item.id}`} className="btn btn-dark m-1">
                  Buy Now
                </Link>
                <button className="btn btn-dark m-1" onClick={() => addProduct(item)}>
                  Add to Cart
                </button>
              </div>
            </div>
          )
        ))}
      </div>
    </div>
  );

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row">
          {loading ? <Skeleton height={400} width={400} /> : <ShowProduct />}
        </div>
        <div className="row my-5 py-5">
          <div className="d-none d-md-block">
            <h2 className="">You may also Like</h2>
            <Marquee pauseOnHover={true} pauseOnClick={true} speed={50}>
              {loading2 ? <Loading2 /> : <ShowSimilarProduct />}
            </Marquee>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Product;
