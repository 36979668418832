import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Footer, Navbar } from "../components";
import { authenticate } from '../pages/AuthService';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [loginErr, setLoginErr] = useState('');
  
  const navigate = useNavigate();
  
  const formInputChange = (formField, value) => {
    if (formField === "email") {
      setEmail(value);
    }
    if (formField === "password") {
      setPassword(value);
    }
  };
  
  const validation = () => {
    return new Promise((resolve, reject) => {
      if (email === '' && password === '') {
        setEmailErr("Email is Required");
        setPasswordErr("Password is required")
        reject({ email: "Email is Required", password: "Password is required" });
      }
      else if (email === '') {
        setEmailErr("Email is Required")
        reject({ email: "Email is Required", password: "" });
      }
      else if (password === '') {
        setPasswordErr("Password is required")
        reject({ email: "", password: "Password is required" });
      }
      else if (password.length < 8) {
        setPasswordErr("must be 8 characters")
        reject({ email: "", password: "must be 8 characters" });
      }
      else {
        resolve({ email: "", password: "" });
      }
    });
  };
  
  const handleClick = (e) => {
    e.preventDefault();
    setEmailErr("");
    setPasswordErr("");
    setLoginErr("");
    
    validation()
      .then(() => {
        authenticate(email, password)
          .then((data) => {
            setLoginErr('');
            console.log('Login successful:', data);
            navigate('/');
          })
          .catch((err) => {
            console.log('Login error:', err);
            setLoginErr(err.message);
          });
      })
      .catch((err) => {
        console.log('Validation errors:', err);
      });
  };
  
  return (
    <>
      <Navbar />
      <div className="container my-3 py-3">
        <h1 className="text-center">Login</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div className="my-3">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => formInputChange("email", e.target.value)}
                />
                {emailErr && <div className="text-danger">{emailErr}</div>}
              </div>
              <div className="my-3">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => formInputChange("password", e.target.value)}
                />
                {passwordErr && <div className="text-danger">{passwordErr}</div>}
              </div>
              <div className="my-3">
                <p>New Here? <Link to="/register" className="text-decoration-underline text-info">Register</Link></p>
              </div>
              {loginErr && <div className="text-danger text-center">{loginErr}</div>}
              <div className="text-center">
                <button className="my-2 mx-auto btn btn-dark" type="submit" onClick={handleClick}>
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
