import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Footer } from '../components';
import { Link } from 'react-router-dom';
import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import UserPool from './userpool';

const PastOrders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortByDate, setSortByDate] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserEmail = () => {
            const userPool = new CognitoUserPool({
                UserPoolId: 'us-east-1_tAsvjzrLg',
                ClientId: '6s9871odv132n3eui3ust8mbj5'
            });
            const currentUser = userPool.getCurrentUser();

            if (currentUser) {
                currentUser.getSession((err, session) => {
                    if (err || !session.isValid()) {
                        console.error('Error fetching user session:', err);
                        alert('Login is required to access the orders page.');
                        navigate('/login');
                        setLoading(false);
                        return;
                    }

                    const userEmail = session.getIdToken().payload.email;
                    setUserEmail(userEmail);
                    console.log("Logged-in user's email:", userEmail); // Log the email of the logged-in user
                });
            } else {
                console.error('No current user');
                alert('Login is required to access the orders page.');
                navigate('/login');
                setLoading(false);
            }
        };

        fetchUserEmail();
    }, [navigate]);

    useEffect(() => {
        const fetchOrders = async () => {
            if (!userEmail) return;

            try {
                const response = await fetch(`https://8h5honhhbj.execute-api.us-east-1.amazonaws.com/prod/pastOrders?email=${userEmail}`);
                const data = await response.json();
                if (response.ok) {
                    setOrders(data);
                } else {
                    setError(data.message || 'Failed to fetch past orders');
                }
            } catch (error) {
                console.error('Error fetching orders:', error);
                setError('Failed to fetch past orders');
            } finally {
                setLoading(false);
            }
        };

        if (userEmail) {
            fetchOrders();
        }
    }, [userEmail]);

    const toggleSortByDate = () => {
        setSortByDate(!sortByDate);
    };

    const sortOrdersByDate = (a, b) => {
        if (sortByDate) {
            return new Date(b.orderDate.S) - new Date(a.orderDate.S);
        } else {
            return new Date(a.orderDate.S) - new Date(b.orderDate.S);
        }
    };

    if (loading) {
        return (
            <div className="container my-5">
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="container my-5">
                <div className="alert alert-danger" role="alert">
                    Error: {error}
                </div>
            </div>
        );
    }

    const sortedOrders = [...orders].sort(sortOrdersByDate);

    return (
        <>
            <Navbar />
            <div className="container my-5">
                <div className="d-flex justify-content-end mb-3">
                    <button className="btn btn-secondary" onClick={toggleSortByDate}>
                        {sortByDate ? 'Sort by Oldest First' : 'Sort by Newest First'}
                    </button>
                </div>
                <h2 className="mb-4">Past Orders</h2>
                <div className="scrollable-container" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    {sortedOrders.length === 0 ? (
                        <div className="alert alert-info" role="alert">
                            No past orders found.
                        </div>
                    ) : (
                        <div className="row g-4">
                            {sortedOrders.map((order, index) => (
                                <div key={index} className="col-12">
                                    <div className="card h-100 shadow-sm">
                                        <div className="card-body p-2">
                                            <h6 className="card-subtitle mb-2 text-muted">Order Date: {new Date(order.orderDate.S).toLocaleDateString()}</h6>
                                            <p className="card-text">Status: {order.Status.S}</p>
                                            <p className="card-text">Total Amount: ${parseFloat(order.TotalAmount.N).toFixed(2)}</p>
                                            <ul className="list-group list-group-flush">
                                                {order.Items.L.map((item, index) => (
                                                    <li key={index} className="list-group-item p-2">
                                                        <Link to={"/product/" + item.M.ProductID.S} className="btn btn-dark btn-sm m-1">Title: {item.M.ProductDetails.title.S}</Link>
                                                        {item.M.ProductDetails.image && (
                                                            <div className="mb-2">
                                                                <img src={item.M.ProductDetails.image} alt="Product" className="img-thumbnail" style={{ width: '100px', height: '100px' }} />
                                                            </div>
                                                        )}
                                                        <div>Price: ${parseFloat(item.M.ProductDetails.price.N).toFixed(2)}</div>
                                                        <div>Quantity: {item.M.Quantity.N}</div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PastOrders;
