// const cart = []

// const handleCart = (state=cart, action) =>{
//     const product = action.payload
//     switch(action.type){
//         case "ADDITEM":
//             // Check if product already in cart
//             const exist = state.find((x) => x.id === product.id)
//             if(exist){
//                 // Increase the quantity
//                 return state.map((x)=>x.id ===product.id?{...x, qty: x.qty+1}:x)
//             }
//             else{
//                 return [...state, {...product, qty:1}]
//             }
//             break;
//         case "DELITEM":
//             const exist2 = state.find((x) => x.id === product.id)
//             if(exist2.qty === 1){
//                 return state.filter((x)=>x.id!==exist2.id)
//             }
//             else{
//                 return state.map((x)=> x.id===product.id?{...x, qty:x.qty-1}:x)
//             }
//             break;

//         default:
//             return state
//             break;
//     }
// }

// export default handleCart
const cart = [];

const handleCart = (state = cart, action) => {
    const product = action.payload;

    switch(action.type) {
        case "ADDITEM":
            // Check if the product is already in the cart
            const exist = state.find((x) => x.id === product.id);
            if (exist) {
                // Increase the quantity of the existing product
                return state.map((x) => x.id === product.id ? { ...x, qty: x.qty + 1 } : x);
            } else {
                // Add the new product to the cart with a quantity of 1
                return [...state, { ...product, qty: 1 }];
            }
        
        case "DELITEM":
            const exist2 = state.find((x) => x.id === product.id);
            if (exist2.qty === 1) {
                // Remove the product from the cart if its quantity is 1
                return state.filter((x) => x.id !== exist2.id);
            } else {
                // Decrease the quantity of the existing product
                return state.map((x) => x.id === product.id ? { ...x, qty: x.qty - 1 } : x);
            }
        
        default:
            return state;
    }
};

export default handleCart;
