// import React, { useState } from 'react';
// import { Footer, Navbar } from "../components";
// import { Link, useNavigate } from 'react-router-dom';
// import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
// import userpool from '../pages/userpool';

// const Register = () => {
//     const [username, setUsername] = useState('');
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('');
//     const [verificationCode, setVerificationCode] = useState('');
//     const [usernameErr, setUsernameErr] = useState('');
//     const [emailErr, setEmailErr] = useState('');
//     const [passwordErr, setPasswordErr] = useState('');
//     const [registrationErr, setRegistrationErr] = useState('');
//     const [isRegistered, setIsRegistered] = useState(false);
//     const [isVerifying, setIsVerifying] = useState(false);

//     const navigate = useNavigate();

//     const formInputChange = (formField, value) => {
//         if (formField === "username") {
//             setUsername(value);
//         }
//         if (formField === "email") {
//             setEmail(value);
//         }
//         if (formField === "password") {
//             setPassword(value);
//         }
//         if (formField === "verificationCode") {
//             setVerificationCode(value);
//         }
//     };

//     const validation = () => {
//         return new Promise((resolve, reject) => {
//             let isValid = true;

//             if (username === '') {
//                 setUsernameErr("Full Name is required");
//                 isValid = false;
//             } else {
//                 setUsernameErr('');
//             }

//             if (email === '') {
//                 setEmailErr("Email is required");
//                 isValid = false;
//             } else {
//                 setEmailErr('');
//             }

//             if (password === '') {
//                 setPasswordErr("Password is required");
//                 isValid = false;
//             } else if (password.length < 6) {
//                 setPasswordErr("Password should be at least 6 characters");
//                 isValid = false;
//             } else {
//                 setPasswordErr('');
//             }

//             if (isValid) {
//                 resolve();
//             } else {
//                 reject();
//             }
//         });
//     };

//     const handleClick = async (e) => {
//         e.preventDefault();
//         setUsernameErr("");
//         setEmailErr("");
//         setPasswordErr("");
//         setRegistrationErr("");

//         try {
//             await validation();

//             const attributeList = [
//                 new CognitoUserAttribute({
//                     Name: "name",
//                     Value: username,
//                 }),
//                 new CognitoUserAttribute({
//                     Name: "email",
//                     Value: email,
//                 }),
//             ];

//             userpool.signUp(username, password, attributeList, null, (err, data) => {
//                 if (err) {
//                     console.log(err);
//                     setRegistrationErr(err.message || JSON.stringify(err));
//                 } else {
//                     console.log(data);
//                     setIsRegistered(true);
//                 }
//             });
//         } catch (error) {
//             console.log("Validation failed");
//         }
//     };

//     const handleVerification = (e) => {
//         e.preventDefault();
//         setIsVerifying(true);
//         const user = new CognitoUser({
//             Username: username,
//             Pool: userpool,
//         });

//         user.confirmRegistration(verificationCode, true, (err, result) => {
//             if (err) {
//                 console.log(err);
//                 alert(err.message || JSON.stringify(err));
//                 setIsVerifying(false);
//             } else {
//                 console.log('Verification successful:', result);
//                 alert('Verification successful');
//                 navigate('/login');
//             }
//         });
//     };

//     return (
//         <>
//             <Navbar />
//             <div className="container my-3 py-3">
//                 <h1 className="text-center">Register</h1>
//                 <hr />
//                 <div className="row my-4 h-100">
//                     <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
//                         {!isRegistered ? (
//                             <form>
//                                 <div className="form my-3">
//                                     <label htmlFor="Name">Full Name</label>
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         id="Name"
//                                         placeholder="Enter Your Name"
//                                         value={username}
//                                         onChange={(e) => formInputChange("username", e.target.value)}
//                                     />
//                                     {usernameErr && <small className="text-danger">{usernameErr}</small>}
//                                 </div>
//                                 <div className="form my-3">
//                                     <label htmlFor="Email">Email address</label>
//                                     <input
//                                         type="email"
//                                         className="form-control"
//                                         id="Email"
//                                         placeholder="name@example.com"
//                                         value={email}
//                                         onChange={(e) => formInputChange("email", e.target.value)}
//                                     />
//                                     {emailErr && <small className="text-danger">{emailErr}</small>}
//                                 </div>
//                                 <div className="form my-3">
//                                     <label htmlFor="Password">Password</label>
//                                     <input
//                                         type="password"
//                                         className="form-control"
//                                         id="Password"
//                                         placeholder="Password"
//                                         value={password}
//                                         onChange={(e) => formInputChange("password", e.target.value)}
//                                     />
//                                     {passwordErr && <small className="text-danger">{passwordErr}</small>}
//                                 </div>
//                                 {registrationErr && <div className="alert alert-danger">{registrationErr}</div>}
//                                 <div className="my-3">
//                                     <p>Already have an account? <Link to="/login" className="text-decoration-underline text-info">Login</Link></p>
//                                 </div>
//                                 <div className="text-center">
//                                     <button className="my-2 mx-auto btn btn-dark" type="submit" onClick={handleClick}>
//                                         Register
//                                     </button>
//                                 </div>
//                                 {registrationErr && <div className="alert alert-danger">{registrationErr}</div>}
//                             </form>
//                         ) : (
//                             <form>
//                                 <div className="form my-3">
//                                     <label htmlFor="VerificationCode">Verification Code</label>
//                                     <input
//                                         type="text"
//                                         className="form-control"
//                                         id="VerificationCode"
//                                         placeholder="Enter Verification Code"
//                                         value={verificationCode}
//                                         onChange={(e) => formInputChange("verificationCode", e.target.value)}
//                                     />
//                                 </div>
//                                 <div className="text-center">
//                                     <button className="my-2 mx-auto btn btn-dark" type="submit" onClick={handleVerification} disabled={isVerifying}>
//                                         {isVerifying ? 'Verifying...' : 'Verify'}
//                                     </button>
//                                 </div>
//                             </form>
//                         )}
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </>
//     );
// };

// export default Register;

import React, { useState } from 'react';
import { Footer, Navbar } from "../components";
import { Link, useNavigate } from 'react-router-dom';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import userpool from '../pages/userpool';

const Register = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [usernameErr, setUsernameErr] = useState('');
    const [emailErr, setEmailErr] = useState('');
    const [passwordErr, setPasswordErr] = useState('');
    const [registrationErr, setRegistrationErr] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);

    const navigate = useNavigate();

    const formInputChange = (formField, value) => {
        if (formField === "username") {
            setUsername(value);
        }
        if (formField === "email") {
            setEmail(value);
        }
        if (formField === "password") {
            setPassword(value);
        }
        if (formField === "verificationCode") {
            setVerificationCode(value);
        }
    };

    const validation = () => {
        return new Promise((resolve, reject) => {
            let isValid = true;

            if (username === '') {
                setUsernameErr("Full Name is required");
                isValid = false;
            } else {
                setUsernameErr('');
            }

            if (email === '') {
                setEmailErr("Email is required");
                isValid = false;
            } else {
                setEmailErr('');
            }

            if (password === '') {
                setPasswordErr("Password is required");
                isValid = false;
            } else if (password.length < 6) {
                setPasswordErr("Password should be at least 6 characters");
                isValid = false;
            } else {
                setPasswordErr('');
            }

            if (isValid) {
                resolve();
            } else {
                reject();
            }
        });
    };

    const checkEmailUniqueness = async (email) => {
        try {
            const response = await fetch(`https://8h5honhhbj.execute-api.us-east-1.amazonaws.com/prod/emailUniqueness?email=${email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            if (response.status !== 200) {
                throw new Error(data.error);
            }
            return true;  // Email is unique
        } catch (error) {
            throw new Error(error.message);
        }
    };

    const handleClick = async (e) => {
        e.preventDefault();
        setUsernameErr("");
        setEmailErr("");
        setPasswordErr("");
        setRegistrationErr("");

        try {
            await validation();
            // Check email uniqueness
            try {
                await checkEmailUniqueness(email);
            } catch (error) {
                setEmailErr(error.message);
                return;
            }

            const attributeList = [
                new CognitoUserAttribute({
                    Name: "name",
                    Value: username,
                }),
                new CognitoUserAttribute({
                    Name: "email",
                    Value: email,
                }),
            ];

            userpool.signUp(username, password, attributeList, null, (err, data) => {
                if (err) {
                    console.log(err);
                    setRegistrationErr(err.message || JSON.stringify(err));
                } else {
                    console.log(data);
                    setIsRegistered(true);
                }
            });
        } catch (error) {
            console.log("Validation failed");
        }
    };

    const handleVerification = (e) => {
        e.preventDefault();
        setIsVerifying(true);
        const user = new CognitoUser({
            Username: username,
            Pool: userpool,
        });

        user.confirmRegistration(verificationCode, true, (err, result) => {
            if (err) {
                console.log(err);
                alert(err.message || JSON.stringify(err));
                setIsVerifying(false);
            } else {
                console.log('Verification successful:', result);
                alert('Verification successful');
                navigate('/login');
            }
        });
    };

    return (
        <>
            <Navbar />
            <div className="container my-3 py-3">
                <h1 className="text-center">Register</h1>
                <hr />
                <div className="row my-4 h-100">
                    <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
                        {!isRegistered ? (
                            <form>
                                <div className="form my-3">
                                    <label htmlFor="Name">Full Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="Name"
                                        placeholder="Enter Your Name"
                                        value={username}
                                        onChange={(e) => formInputChange("username", e.target.value)}
                                    />
                                    {usernameErr && <small className="text-danger">{usernameErr}</small>}
                                </div>
                                <div className="form my-3">
                                    <label htmlFor="Email">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="Email"
                                        placeholder="name@example.com"
                                        value={email}
                                        onChange={(e) => formInputChange("email", e.target.value)}
                                    />
                                    {emailErr && <small className="text-danger">{emailErr}</small>}
                                </div>
                                <div className="form my-3">
                                    <label htmlFor="Password">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="Password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => formInputChange("password", e.target.value)}
                                    />
                                    {passwordErr && <small className="text-danger">{passwordErr}</small>}
                                </div>
                                {registrationErr && <div className="alert alert-danger">{registrationErr}</div>}
                                <div className="my-3">
                                    <p>Already have an account? <Link to="/login" className="text-decoration-underline text-info">Login</Link></p>
                                </div>
                                <div className="text-center">
                                    <button className="my-2 mx-auto btn btn-dark" type="submit" onClick={handleClick}>
                                        Register
                                    </button>
                                </div>
                                {registrationErr && <div className="alert alert-danger">{registrationErr}</div>}
                            </form>
                        ) : (
                            <form>
                                <div className="form my-3">
                                    <label htmlFor="VerificationCode">Verification Code</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="VerificationCode"
                                        placeholder="Enter Verification Code"
                                        value={verificationCode}
                                        onChange={(e) => formInputChange("verificationCode", e.target.value)}
                                    />
                                </div>
                                <div className="text-center">
                                    <button className="my-2 mx-auto btn btn-dark" type="submit" onClick={handleVerification} disabled={isVerifying}>
                                        {isVerifying ? 'Verifying...' : 'Verify'}
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Register;
